import React from 'react';

import { Container } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faLinkedin, faInstagram, faMedium, faGithub } from '@fortawesome/free-brands-svg-icons';


export default function Footer() {
  return (
    <footer id="contact" className="footer">
      <Container>
        <div className="l-footer-heading">
          <h2 className="heading-2">Let’s talk!</h2>
        </div>

        <div className="row justify-content-center l-contact">
          <div className="col-auto l-contact__item">
            <div className="l-contact-icon">
              <FontAwesomeIcon icon={faPaperPlane} />
            </div>
            <div className="contact-title">Mail me:</div>
            <div className="contact-text"><a className="contact-email" href="mailto:nayra.ncruz@gmail.com">nayra.ncruz@gmail.com</a></div>
          </div>

          <div className="col-auto l-contact__item">
            <div className="l-contact-icon">
              <FontAwesomeIcon icon={faWhatsapp} />
            </div>
            <div className="contact-title">Send a Whatsapp:</div>
            <div className="contact-text">+55 61 9130-5101</div>
          </div>

          <div className="col-auto l-contact__item">
            <div className="l-contact-icon">
              <FontAwesomeIcon icon={faMobileAlt} />
            </div>
            <div className="contact-title">Phone me:</div>
            <div className="contact-text">+55 61 9522-9277</div>
          </div>
        </div>

        <h3 className="heading-3">Where to find me</h3>

        <div className="footer-social">
          <a className="footer-social__link" href="https://www.linkedin.com/in/nayra-cruz/">
            <FontAwesomeIcon icon={faLinkedin} />
            <span className="sr-only">Linkedin</span>
          </a>

          <a className="footer-social__link" href="https://www.instagram.com/nayraweb">
            <FontAwesomeIcon icon={faInstagram} />
            <span className="sr-only">Instagram</span>
          </a>

          <a className="footer-social__link" href="https://medium.com/@nayracruz/">
            <FontAwesomeIcon icon={faMedium} />
            <span className="sr-only">Medium</span>
          </a>

          <a className="footer-social__link" href="https://github.com/NayraCruz/">
            <FontAwesomeIcon icon={faGithub} />
            <span className="sr-only">Github</span>
          </a>

          <a className="footer-social__link" href="https://wa.me/5561981305101?text=Olá,%20venho%20por%20meio%20do%20seu%20site%20www.nayracruz.com.br.">
            <FontAwesomeIcon icon={faWhatsapp} />
            <span className="sr-only">Whatsapp</span>
          </a>
        </div>
      </Container>
    </footer>
  );
}