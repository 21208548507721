import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Copyright from "./components/Copyright";
//import Home from './pages/Home';
import About from './pages/Home/_about';

function App() {
  return (
    <div>
      <Header></Header>
      <About></About>
      <Footer></Footer>
      <Copyright></Copyright>
    </div>
  );
}

export default App;
