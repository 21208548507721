import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function About() {
  return (
    <section id="about" className="page-section">
      <div className="container">
        <h2 className="heading-2">About</h2>

        <div className="about-text">
          <p>Hello,</p>
          <p>I'm a web, ux, ui <span className="highlight">designer</span>, from Brasília (Brazil).</p>
          <p>I started my career in the web world in 2009 as a <span className="highlight">web designer</span>.</p>
          <p>In <span className="highlight">2017</span> I started working with <span className="highlight">web accessibility</span> with focus on <span className="highlight">accessible codes</span> for <span className="highlight">screen readers</span>.</p>
          <p>Since then I have been passionate about <span className="highlight">usability</span>, <span className="highlight">accessibility</span> and <span className="highlight">digital inclusion</span>.</p>
          <p><span className="highlight">Follow me</span> on my social networks:</p>
        </div>

        <div className="l-about-social">
          <a className="hvr-icon-grow about-social" href="https://www.linkedin.com/in/nayra-cruz/">
            <FontAwesomeIcon icon={faLinkedinIn} className="hvr-icon" />
            <span>Linkedin</span>
          </a>

          <a className="hvr-icon-grow about-social" href="https://www.instagram.com/nayraweb">
            <FontAwesomeIcon icon={faInstagram} className="hvr-icon" />
            <span>Instagram</span>
          </a>
        </div>
      </div>
    </section>
  );
}