import React from 'react';

import { Container } from 'react-bootstrap';
//import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

export default function Intro() {
    return (
        <section className="intro">
            <Container>
                <p>
                    <span className="intro__first-text">Hi! I’m <span className="intro__pink-text">Nayra Cruz</span>. I work with:</span>

                    <span className="intro__pink-text intro__big-text">ux/ui design</span>

                    <span className="sr-only">and</span>

                    <span className="intro__pink-text intro__big-text">web accessibility</span>
                </p>

                {/* <div className="l-intro__button">
                    <Button className="btn--outline-primary" variant="outline-primary">Check my portfolio</Button>
                </div> */}

                <a href="#about" className="intro__next">
                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                    <span className="sr-only">Ir para a próxima seção</span>
                </a>
            </Container>
        </section>

    );
}
