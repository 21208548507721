import React from 'react';
import { Container } from 'react-bootstrap';

export default function Copyright() {
  return (
    <div className="copyright">
      <Container className="d-flex justify-content-center align-items-center">
        <p>© 2020 - Nayra Cruz - All rights reserved.</p>
      </Container>
    </div>
  );
}