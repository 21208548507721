import React from 'react';

import { Container } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Intro from '../Intro';

import myLogoMobile from '../../assets/images/logo-nayra-fundo-preto--mobile.png';
import myLogo from '../../assets/images/logo-nayra-fundo-preto.svg';

export default function Header() {
    return (
        <header className="header" id="header">

            <Navbar className="navbar--home fixed-top" expand="lg">
                <Container>
                    <Navbar.Brand href="#header">
                        <picture>
                            <source media="(max-width: 576px)" srcset={myLogoMobile}></source>
                            <img className="img-fluid" src={myLogo} alt=""/>
                        </picture>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="#header" className="hvr-underline-reveal">Home</Nav.Link>
                            <Nav.Link href="#about" className="hvr-underline-reveal">About</Nav.Link>
                            {/* <Nav.Link href="#skills" className="hvr-underline-reveal">Skills</Nav.Link>
                            <Nav.Link href="#work" className="hvr-underline-reveal">Work</Nav.Link> */}
                            <Nav.Link href="#contact" className="hvr-underline-reveal">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
           
            <Intro></Intro>
            
        </header>
    );
}
